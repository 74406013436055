// ** WEB3 Imports
import { useState, useEffect } from "react";

import { useContractRead } from "wagmi";
import { ethers } from "ethers";
import { CONTRACT_INFO } from "../../index";
const useGetPlatformPercentages = () => {
    
  const [dOPoolPercentage, setDOPoolPercentage] = useState(2);
  const [iOFeePercentage, setIOFeePercentage] = useState(2);
  const [mFPercentage, setMFPercentage] = useState(2);
  const [tDPPercentage, setTDPPercentage] = useState(2);
  const [oDPPercentage, setODPPercentage] = useState(2);
  const [fTTPercentage, setfTTPercentage] = useState(2);
  const [wOPoolPercentage, setWOPoolPercentage] = useState(2);

  // const {
  //   data: dOPP,
  //   refetch: refetchDOPoolPercentage,
  // } = useContractRead({
  //   address: CONTRACT_INFO.stake.address,
  //   abi: CONTRACT_INFO.stake.abi,
  //   functionName: "dOPoolPercentage",
  // });

  // const {
  //   data: IOFP,
  //   refetch: refetchIOPercentage,
  // } = useContractRead({
  //   address: CONTRACT_INFO.stake.address,
  //   abi: CONTRACT_INFO.stake.abi,
  //   functionName: "IOPoolPercentage",
  // });
  const {
    data: MFP,
    refetch: refetchMFPercentage,
  } = useContractRead({
    address: CONTRACT_INFO.stake.address,
    abi: CONTRACT_INFO.stake.abi,
    functionName: "maintainceFeePercentage",
  });
  const {
    data: TDPP,
    refetch: refetchtDPPercentage,
  } = useContractRead({
    address: CONTRACT_INFO.stake.address,
    abi: CONTRACT_INFO.stake.abi,
    functionName: "tdividentPayoutPercentage",
  });
  const {
    data: ODPP,
    refetch: refetchtODPPercentage,
  } = useContractRead({
    address: CONTRACT_INFO.stake.address,
    abi: CONTRACT_INFO.stake.abi,
    functionName: "odividentPayoutPercentage",
  });
  const {
    data: FTTP,
    refetch: refetchtFTTercentage,
  } = useContractRead({
    address: CONTRACT_INFO.stake.address,
    abi: CONTRACT_INFO.stake.abi,
    functionName: "flowToTreasuryPercentage",
  });
  // const {
  //   data: WOPP,
  //   refetch: refetchWOPoolPercentage,
  // } = useContractRead({
  //   address: CONTRACT_INFO.stake.address,
  //   abi: CONTRACT_INFO.stake.abi,
  //   functionName: "wOPoolPercentage",
  // });
  const formatNumber=(number)=>{
    return Number(number).toString()/100
  }

  useEffect(() => {
    // if (dOPP) {
    //     setDOPoolPercentage(formatNumber(dOPP));
    // }
    // if (IOFP) {
    //     setIOFeePercentage(formatNumber(IOFP));
    // }
    if (MFP) {
        setMFPercentage(formatNumber(MFP));
    }
    if (TDPP) {
        setTDPPercentage(formatNumber(TDPP));
      }
      // if (WOPP) {
      //   setTDPPercentage(formatNumber(WOPP));
      // }
      if(ODPP){
        setODPPercentage(formatNumber(ODPP))
      }
      if(FTTP){
        setfTTPercentage(formatNumber(FTTP))
      }
  }, [TDPP,MFP,ODPP,FTTP]);

  const refetch = () => {
    // refetchDOPoolPercentage();
    // refetchIOPercentage();
    refetchMFPercentage();
    refetchtDPPercentage();
    // refetchWOPoolPercentage();
    refetchtODPPercentage()
    refetchtFTTercentage()
  };

  return {
    percentages:{
        dOPoolPercentage,
        // iOFeePercentage,
        mFPercentage,
        tDPPercentage, 
        wOPoolPercentage,
        oDPPercentage,
        fTTPercentage
    },
    refetch,
  };
};

export default useGetPlatformPercentages;
