// ** Web3 Imports
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig, configureChains, createConfig } from "wagmi";
import { bscTestnet,sepolia } from "wagmi/chains";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { walletConnectProvider, EIP6963Connector } from "@web3modal/wagmi";
import { publicProvider } from "wagmi/providers/public";

const projectId = "4dc5d24e1f5fd35a7cc538ffe17eda3b";
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "http://localhost:3001",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};
const networks = {
  97: sepolia,
  11155111:bscTestnet
};
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [networks[ Number(process.env.REACT_APP_CHAIN_ID)]],
  [walletConnectProvider({ projectId }), publicProvider()]
);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    // new MetaMaskConnector({ chains}),
    new WalletConnectConnector({
      chains,
      options: { projectId, showQrModal: false, metadata },
    }),
    new EIP6963Connector({ chains }),
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
  ],
  publicClient,
});

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  includeWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  ],
});

export function Web3Modal({ children }) {
  return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>;
}
