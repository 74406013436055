// api/index.js
import axios from "axios";
import { setupRequestInterceptor, setupResponseInterceptor } from "./interceptors";

const baseURL = process.env.REACT_APP_API_BASE_URL||'http://localhost:4000';
const api = axios.create({
  baseURL,
});

// Setup request and response interceptors
setupRequestInterceptor(api);
setupResponseInterceptor(api);

export default api;
