import React, { useRef,useState,useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Web3Modal } from "./context/web3Modal";
import { routes } from "./routes";
import PrivateRoute from "./routes/privateRoute";
import SocketContext from "./context/socket";
import Socket from "./helpers/socket";
function App() {
  const [socket, setSocket] = useState(undefined);
  const cursor = useRef(null);
  const changePosition = (e) => {
    cursor.current.style.top = `${e.clientY}px`;
    cursor.current.style.left = `${e.clientX}px`;
  };
  useEffect(() => {
    if (!socket) {
      const connectedSocket = Socket.connectSocket();
      setSocket(connectedSocket);
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);
  return (
    <div className="app" onMouseMove={changePosition}>
      <div className="cursor-style" ref={cursor}></div>
      <Web3Modal>
      <SocketContext.Provider value={socket}>
        <Routes>
          {routes.map((route, i) => {
            return (
              <Route
                path={route.path}
                key={i}
                element={
                  <PrivateRoute access={route.access}>
                    <route.layout>
                      <route.component />
                    </route.layout>
                  </PrivateRoute>
                }
              />
            );
          })}
        </Routes>
        </SocketContext.Provider>
      </Web3Modal>
    </div>
  );
}

export default App;
