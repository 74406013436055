// ** WEB3 Imports
import { useContractWrite, useWaitForTransaction } from "wagmi";
import {CONTRACT_INFO} from "../../index";
const tokenABI = CONTRACT_INFO.token.abi;
const tokenContractAddress = CONTRACT_INFO.token.address;

const useApprove = () => {
  //approve contract

  const {
    isError: isApproveSentError,
    data: approveSentTx,
    isLoading: isApproveTxInProgress,
    isSuccess: isApproveTxSent,
    write: approve,
    error: approveSentError
  } = useContractWrite({
    address: tokenContractAddress,
    abi: tokenABI,
    functionName: "approve",
  });
  const {
    isLoading: isApproveWaiting,
    isSuccess: isApproveCompleted,
    isError: isApproveError,
    error: approveTxError,
    data: approveResp
  } = useWaitForTransaction({
    hash: approveSentTx?.hash,
  });

  return {
    //approve operation
    isApproveSentError,
    approveSentTx,
    isApproveTxInProgress,
    isApproveTxSent,
    approve, // Renamed the function
    isApproveCompleted,
    isApproveError,
    isApproveWaiting,
    approveSentError,
    approveTxError,
    approveResp
  };
};

export default useApprove;
