import React from "react";

const Layout = (props) => {
  return (
    <>
     {props.children}
    </>
  );
};

export default Layout;
