import { useContractWrite, useWaitForTransaction } from "wagmi";
import { CONTRACT_INFO } from "../../index";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { extractErrorMessage } from "../../../helpers/common";

const address = CONTRACT_INFO.stake.address;
const abi = CONTRACT_INFO.stake.abi;

const useAddPreInvestment = ({setLoader}) => {
  const {
    isError: isAddPreInvestmentSentError,
    data: addPreInvestmentSentTx,
    isLoading: isAddPreInvestmentTxInProgress,
    isSuccess: isAddPreInvestmentTxSent,
    write: addPreInvestment, // Function to interact with the addPreInvestment contract
    error: addPreInvestmentSentError
  } = useContractWrite({
    address,
    abi,
    functionName: "stakeTokensByOwner", // Assuming this function remains the same in your ABI
  });

  const {
    isLoading: isAddPreInvestmentWaiting,
    isSuccess: isAddPreInvestmentCompleted,
    isError: isAddPreInvestmentError,
    error: addPreInvestmentTxError,
    data: addPreInvestmentResp
  } = useWaitForTransaction({
    hash: addPreInvestmentSentTx?.hash,
  });
  
  useEffect(()=>{
    if(addPreInvestmentSentError || addPreInvestmentTxError){
        if(setLoader){
            setLoader(false)
        }
      toast.error(extractErrorMessage(addPreInvestmentSentError?.message))
    }

  },[addPreInvestmentSentError,addPreInvestmentTxError])

  return {
    // addPreInvestment operation
    isAddPreInvestmentSentError,
    addPreInvestmentSentTx,
    isAddPreInvestmentTxInProgress,
    isAddPreInvestmentTxSent,
    addPreInvestment,
    isAddPreInvestmentCompleted,
    isAddPreInvestmentError,
    isAddPreInvestmentWaiting,
    addPreInvestmentSentError,
    addPreInvestmentTxError,
    addPreInvestmentResp
  };
};

export default useAddPreInvestment;
