// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overview_overview__m9tjN {
  position: relative;
  z-index: 9;
  background-color: var(--cardBg);
  border-radius: 12px;
  padding: 30px;
}
@media screen and (max-width: 1199px) {
  .overview_overview__m9tjN {
    padding: 15px;
  }
}
@media screen and (max-width: 576px) {
  .overview_overview__m9tjN {
    padding: 15px;
  }
}
.overview_overview__m9tjN .overview_btn__h3B05 {
  background-color: #b31017;
  border-radius: 4px;
  color: #fff;
  padding: 10px 20px;
  border: none;
}
@media screen and (max-width: 576px) {
  .overview_overview__m9tjN .overview_btn__h3B05 {
    width: 100%;
  }
}
.overview_overview__m9tjN .overview_title__aDzMu {
  color: #e09200;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .overview_overview__m9tjN .overview_title__aDzMu {
    text-align: center;
  }
}
.overview_overview__m9tjN .overview_card__ftyyu {
  padding: 30px 0;
}
.overview_overview__m9tjN .overview_card__ftyyu .overview_title__aDzMu {
  font-size: 28px;
  font-weight: 600;
  color: var(--txt);
  margin: 0;
}
@media screen and (max-width: 1199px) {
  .overview_overview__m9tjN .overview_card__ftyyu .overview_title__aDzMu {
    font-size: 22px;
  }
}
.overview_overview__m9tjN .overview_card__ftyyu .overview_subtitle__HheRh {
  font-size: 14px;
  font-weight: 400;
  color: #05AF5A;
}
@media screen and (max-width: 1199px) {
  .overview_overview__m9tjN .overview_card__ftyyu .overview_subtitle__HheRh {
    font-size: 12px;
  }
}
@media screen and (max-width: 767px) {
  .overview_overview__m9tjN .overview_card__ftyyu .overview_subtitle__HheRh {
    text-align: center;
  }
}
.overview_overview__m9tjN .overview_border_bottom__xpEtF {
  border-bottom: 1px solid var(--border);
}`, "",{"version":3,"sources":["webpack://./src/components/Overview/overview.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,+BAAA;EACA,mBAAA;EACA,aAAA;AACF;AAAE;EANF;IAOI,aAAA;EAGF;AACF;AAFE;EATF;IAUI,aAAA;EAKF;AACF;AAJE;EACE,yBAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;AAMJ;AALI;EANF;IAOI,WAAA;EAQJ;AACF;AANE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;AAQJ;AAPI;EALF;IAMI,kBAAA;EAUJ;AACF;AARE;EACE,eAAA;AAUJ;AATI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;AAWN;AAVM;EALF;IAMI,eAAA;EAaN;AACF;AAXI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAaN;AAZM;EAJF;IAKI,eAAA;EAeN;AACF;AAdM;EAPF;IAQI,kBAAA;EAiBN;AACF;AAdE;EACE,sCAAA;AAgBJ","sourcesContent":[".overview {\n  position: relative;\n  z-index: 9;\n  background-color: var(--cardBg);\n  border-radius: 12px;\n  padding: 30px;\n  @media screen and (max-width: 1199px) {\n    padding: 15px;\n  }\n  @media screen and (max-width: 576px) {\n    padding: 15px;\n  }\n  .btn {\n    background-color: #b31017;\n    border-radius: 4px;\n    color: #fff;\n    padding: 10px 20px;\n    border: none;\n    @media screen and (max-width: 576px) {\n      width: 100%;\n    }\n  }\n  .title {\n    color: #e09200;\n    font-size: 20px;\n    font-weight: 400;\n    margin: 0;\n    @media screen and (max-width: 767px) {\n      text-align: center;\n    }\n  }\n  .card {\n    padding: 30px 0;\n    .title {\n      font-size: 28px;\n      font-weight: 600;\n      color: var(--txt);\n      margin: 0;\n      @media screen and (max-width: 1199px) {\n        font-size: 22px;\n      }\n    }\n    .subtitle {\n      font-size: 14px;\n      font-weight: 400;\n      color: #05AF5A;\n      @media screen and (max-width: 1199px) {\n        font-size: 12px;\n      }\n      @media screen and (max-width: 767px) {\n        text-align: center;\n      }\n    }\n  }\n  .border_bottom {\n    border-bottom: 1px solid var(--border);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overview": `overview_overview__m9tjN`,
	"btn": `overview_btn__h3B05`,
	"title": `overview_title__aDzMu`,
	"card": `overview_card__ftyyu`,
	"subtitle": `overview_subtitle__HheRh`,
	"border_bottom": `overview_border_bottom__xpEtF`
};
export default ___CSS_LOADER_EXPORT___;
