import { useContractWrite, useWaitForTransaction } from "wagmi";
import { CONTRACT_INFO } from "../../index";

const address = CONTRACT_INFO.stake.address;
const abi = CONTRACT_INFO.stake.abi;

const useDisableUser = () => {
  const {
    isError: isDisableUserSentError,
    data: disableUserSentTx,
    isLoading: isDisableUserTxInProgress,
    isSuccess: isDisableUserTxSent,
    write: updateUserStatus, // Function to interact with the disableUser contract
    error: disableUserSentError
  } = useContractWrite({
    address,
    abi,
    functionName: "blockUser", // Updated function name
  });

  const {
    isLoading: isDisableUserWaiting,
    isSuccess: isDisableUserCompleted,
    isError: isDisableUserError,
    error: disableUserTxError,
    data: disableUserResp
  } = useWaitForTransaction({
    hash: disableUserSentTx?.hash,
  });

  return {
    // disableUser operation
    isDisableUserSentError,
    disableUserSentTx,
    isDisableUserTxInProgress,
    isDisableUserTxSent,
    updateUserStatus,
    isDisableUserCompleted,
    isDisableUserError,
    isDisableUserWaiting,
    disableUserSentError,
    disableUserTxError,
    disableUserResp
  };
};

export default useDisableUser;
