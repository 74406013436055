import { SET_WALLET_ADDRESS,SET_USER_DATA } from "../constants";

const initialWalletState = {
  connectedAddress: "",
  user:null,
};

export const AuthReducer = (state = initialWalletState, action) => {
  switch (action.type) {
    case SET_WALLET_ADDRESS:
      return {
        ...state,
        connectedAddress:action.payload
      };
      case SET_USER_DATA:
        return {
          ...state,
          user:action.payload
        };

    default:
      return state;
  }
};
