import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import HeaderComponent from './Header';
import Sidebar from './Sidebar';
import Classes from './dashboardLayout.module.scss';
import { useMediaQuery } from 'react-responsive';

const { Header, Sider, Content } = Layout;

const DashboardLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 575 });
  const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    } else if (isTablet) {
      setCollapsed(true);
    } else if (isDesktop) {
      setCollapsed(false);
    }
  }, [isMobile, isTablet, isDesktop]);

  const sidebarWidth = collapsed ? 80 : (isMobile || isTablet ? 200 : 245);

  const headerStyle = {
    left: sidebarWidth,
    width: `calc(100% - ${sidebarWidth}px)`,
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout className={Classes.layout}>
      <Sider className={Classes.sider} trigger={null} collapsible collapsed={collapsed}>
        <Sidebar collapsed={collapsed} />
      </Sider>
      <Layout style={{ marginLeft: sidebarWidth }}>
        <Header className={`${Classes.headerOuter} d-flex align-items-center`} style={headerStyle}>
          <HeaderComponent collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
        </Header>
        <Content className={Classes.mainOuter}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
