import api from "../index";
const getProjects = () => {
  return api.get(`/project`);
};
const getProjectById=(id)=>{
  return api.get(`/project/${id}`);
}
const createProject = (data) => {
  return api.post(`/admin/project`,data);
};
const updateProject=(id,data)=>{
  return api.patch(`/admin/project/${id}`,data)
}
const updateProjectStatus = (id,data) => {
  return api.patch(`/admin/project/${id}/set-status`,data);
};

export { getProjects,createProject,getProjectById,updateProjectStatus,updateProject };
