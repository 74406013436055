import React, { useEffect, useState } from "react";
import Classes from "./percentage.module.scss";
import Swap from "../../assets/images/icons/yellowSwap.svg";
import { Col, Row, Select } from "antd";
import useGetPlatformPercentages from "../../contract/hooks/stake/useGetPlatformPercentages";
import useChangePlatformPercentages from "../../contract/hooks/stake/useChangePlatformPercentages";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { getProjects } from "../../api/services/project";
import useSetTreasuryPercentage from "../../contract/hooks/stake/useSetTreasuryPercentage";
import { decimalnumbervalidator } from "../../helpers/common";
function PercentageChanges() {
  const { address } = useAccount();
  const [loader, setLoader] = useState(null);
  const [projects, setProjects] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [treasuryPercentage, setTreasurePercentageValue] = useState();
  const [percentagesList, setPercentagesList] = useState([]);
  const { percentages, refetch } = useGetPlatformPercentages();
  const {
    setMaintainceFeePercentage,
    setFlowToTeasuryPercentage,
    setDividentPayoutPercentage,
    setODividentPayoutPercentage,
    isMFeeCompleted,
    isFlowTCompleted,
    isDPCompleted,
    isODPCompleted,
  } = useChangePlatformPercentages(setLoader);
  // const {
  //   setTreasuryPercentage,
  //   isSetTreasuryPercentageCompleted,
  //   isSetTreasuryPercentageTxInProgress,
  //   isSetTreasuryPercentageWaiting,
  // } = useSetTreasuryPercentage();
  // useEffect(() => {
  //   if (isSetTreasuryPercentageCompleted) {
  //     setTreasurePercentageValue("");
  //     toast.success("Transaction completed successfully");
  //   }
  // }, [isSetTreasuryPercentageCompleted]);

  useEffect(() => {
    if (
      isODPCompleted ||
      isDPCompleted ||
      isFlowTCompleted ||
      isMFeeCompleted
    ) {
      refetch();
      setLoader(null);
      toast.success("Transaction completed successfully");
    }
  }, [isODPCompleted, isDPCompleted, isFlowTCompleted, isMFeeCompleted]);
  useEffect(() => {
    if (
      percentages.oDPPercentage &&
      percentages.fTTPercentage &&
      percentages.tDPPercentage &&
      percentages.mFPercentage
    ) {
      const updatedPercentagesArray = [
        {
          name: "Set maintenance fee percentage (%)",
          value: percentages.mFPercentage,
          action: (value) =>
            setMaintainceFeePercentage({
              args: [value * 100],
              from: address,
            }),
        },
        {
          name: "Set treasury divident payout percentage (%)",
          value: percentages.tDPPercentage,
          action: (value) =>
            setDividentPayoutPercentage({
              args: [value * 100],
              from: address,
            }),
        },
        {
          name: "Set ownership divident payout percentage (%)",
          value: percentages.oDPPercentage,
          action: (value) =>
            setODividentPayoutPercentage({
              args: [value * 100],
              from: address,
            }),
        },
        {
          name: "Set flow to treasury percentage (%)",
          value: percentages.fTTPercentage,
          action: (value) =>
            setFlowToTeasuryPercentage({
              args: [value * 100],
              from: address,
            }),
        },
      ];
      setPercentagesList(updatedPercentagesArray);
    }
  }, [
    percentages.oDPPercentage,
    percentages.fTTPercentage,
    percentages.tDPPercentage,
    percentages.mFPercentage,
  ]);
  useEffect(() => {
    getProjectsList();
  }, []);
  const getProjectsList = async () => {
    const res = await getProjects();
    if (res?.data?.data.length > 0) {
      formatProjects(res.data.data);
      setProjectId(res?.data?.data[0]?.projectId);
    }
  };
  const formatProjects = (data) => {
    const formatedData = data.map((item) => {
      return {
        label: item.name,
        value: item.projectId,
      };
    });
    setProjectId(formatedData[0].value);
    setProjects(formatedData);
  };
  const handleChange = (index, value) => {
    const newList = percentagesList.map((item, i) =>
      i === index ? { ...item, value } : item
    );
    setPercentagesList(newList);
  };

  const handleSubmit = (index) => {
    if(!address){
      return toast.error('Please connect wallet')
     }
    setLoader(index);
    const selectedAction = percentagesList[index].action;
    const selectedValue = percentagesList[index].value;
    if (selectedAction) selectedAction(selectedValue);
  };

  return (
    <div className={Classes.percentage}>
      <div className={Classes.heading}>
        <img src={Swap} alt="icon" />
        <p>Percentage Changes (Note:Percentage Sum of Maintenance,Ownership and Flow to treasury should be equal to 100%)</p>
      </div>
      {/* <p>Note: Sum of ownership pool, maintainceFeePercentage</p> */}
      <div className={Classes.card}>
      
        <Row gutter={[50, 50]}>
          {percentagesList.map((percentage, index) => {
            return (
              <Col lg={12} md={24} sm={24} xs={24}>
                <div className={Classes.outer}>
                  <div className={Classes.label}>{percentage.name}</div>
                  <div className="d-md-flex gap-3">
                    <input
                      className="mb-md-0 mb-3"
                      type="number"
                      placeholder="Enter the amount"
                      value={percentage.value}
                      onChange={(e) => {
                        const maxPercentage=percentages.oDPPercentage+
                        percentages.fTTPercentage+
                        percentages.mFPercentage
                        console.log(maxPercentage,"maxPercentage=>")
                        if(maxPercentage >100 ){
                          return toast.error("Percentage Sum of Maintenance,Ownership and Flow to treasury should be equal to 100%")
                        }
                        handleChange(index, e.target.value)
                      }}
                      onKeyDown={(e) => {
                        decimalnumbervalidator(e,false,100);
                      }}
                    />
                    <button
                      className={Classes.submit}
                      onClick={() => handleSubmit(index)}
                    >
                      {loader === index ? "loading" : "Submit"}
                    </button>
                  </div>
                </div>
              </Col>
            );
          })}
          {/* <Col lg={12} md={12} sm={24} xs={24}>
            <div className={`${Classes.outer} mb-4`}>
              <div className={Classes.label}>Select Project</div>
              <Select
                value={projectId}
                style={{
                  width: 210,
                }}
                onChange={(value) => setProjectId(value)}
                options={projects}
              />
            </div>
            <div className={Classes.outer}>
              <div className={Classes.label}>Set treasury percentages</div>
              <div className="d-md-flex gap-3">
                <input
                  className="mb-md-0 mb-3"
                  type="number"
                  placeholder="Enter the amount"
                  onChange={(e) => setTreasurePercentageValue(e.target.value)}
                  value={treasuryPercentage}
                  onKeyDown={(e) => {
                    decimalnumbervalidator(e,false,100);
                  }}
                />
                <button
                  className={Classes.submit}
                  onClick={() =>
                    setTreasuryPercentage({
                      args: [Number(projectId), treasuryPercentage * 100],
                      from: address,
                    })
                  }
                >
                  {isSetTreasuryPercentageTxInProgress ||
                  isSetTreasuryPercentageWaiting
                    ? "loading"
                    : "Submit"}
                </button>
              </div>
            </div>
          </Col> */}
        </Row>
      </div>
    </div>
  );
}

export default PercentageChanges;
