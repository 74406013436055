import api from "../index";

const addFund = (data) => {
    return api.post(`/admin/platform-fund`,data);
  };
  const getProjectStats = () => {
    return api.get('/project/investment')
  };
  
export { addFund,getProjectStats };
