// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmModal_disableModal__tHK9X .confirmModal_content__HNLm- {
  padding: 20px;
}
.confirmModal_disableModal__tHK9X .confirmModal_content__HNLm- p {
  color: var(--txt);
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 30px;
}
.confirmModal_disableModal__tHK9X .confirmModal_content__HNLm- .confirmModal_btn__juTIk {
  height: 40px;
  width: 120px;
  border-radius: 6px;
  color: #fff;
}
.confirmModal_disableModal__tHK9X .confirmModal_content__HNLm- .confirmModal_reject__YITcy {
  background-color: rgba(102, 103, 120, 0.5019607843);
  border: 1px solid rgba(102, 103, 120, 0.5019607843);
}
.confirmModal_disableModal__tHK9X .confirmModal_content__HNLm- .confirmModal_accept__3JnrG {
  background-color: #b31017;
  border: 1px solid #b31017;
}`, "",{"version":3,"sources":["webpack://./src/models/ConfirmModal/confirmModal.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AACI;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AACN;AACI;EACE,YAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;AACN;AACI;EACE,mDAAA;EACA,mDAAA;AACN;AACI;EACE,yBAAA;EACA,yBAAA;AACN","sourcesContent":[".disableModal {\n  .content {\n    padding: 20px;\n    p {\n      color: var(--txt);\n      font-size: 18px;\n      font-weight: 400;\n      text-align: center;\n      margin-bottom: 30px;\n    }\n    .btn {\n      height: 40px;\n      width: 120px;\n      border-radius: 6px;\n      color: #fff;\n    }\n    .reject {\n      background-color: #66677880;\n      border: 1px solid #66677880;\n    }\n    .accept {\n      background-color: #b31017;\n      border: 1px solid #b31017;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disableModal": `confirmModal_disableModal__tHK9X`,
	"content": `confirmModal_content__HNLm-`,
	"btn": `confirmModal_btn__juTIk`,
	"reject": `confirmModal_reject__YITcy`,
	"accept": `confirmModal_accept__3JnrG`
};
export default ___CSS_LOADER_EXPORT___;
