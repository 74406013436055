import { ENV } from "../config"
import stakeAbi from "./stake/abi.json"
import tokenAbi from "./token/abi.json"
export const CONTRACT_INFO={
    token:{
        abi:tokenAbi,
        address:ENV.tokenAddress
    },
    stake:{
        abi:stakeAbi,
        address:ENV.stakeAddress
    }
}