import React, { useEffect, useState } from "react";
import Classes from "./header.module.scss";
import { Button, Input, Tooltip } from "antd";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import UserImg from "../../../assets/images/section/user.svg";
import WalletImg from "../../../assets/images/icons/wallet.svg";
import { ReactComponent as Moon } from "../../../assets/images/icons/moon.svg";
import { ReactComponent as Sun } from "../../../assets/images/icons/sun.svg";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useSignMessage, useAccount, useDisconnect } from "wagmi";
import { validateUser, validateSignature } from "../../../api/services/auth";
import { useDispatch, useSelector } from "react-redux";
import { setWalletAddress } from "../../../store/_actions/auth_action";
import { useNavigate } from "react-router-dom";
const HeaderComponent = ({ collapsed, toggleCollapsed }) => {
  const [darkMode, setDarkMode] = useState(true);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    let timer;
    if (tooltipVisible) {
      timer = setTimeout(() => {
        setTooltipVisible(false);
      }, 1000); // Tooltip will disappear after 3 seconds
    }
    return () => clearTimeout(timer);
  }, [tooltipVisible]);
  const {
    data: signMessageData,
    error,
    isLoading,
    signMessage,
    variables,
  } = useSignMessage();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { open } = useWeb3Modal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // On component mount, set initial theme based on darkMode state
    if (darkMode) {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.add("light-theme");
    }

    return () => {
      // Cleanup function to remove class on unmount
      document.body.classList.remove("dark-theme");
      document.body.classList.remove("light-theme");
    };
  }, [darkMode]);
  useEffect(() => {
    if (address) {
      handleWalletConnect();
    }
  }, [address]);
  useEffect(() => {
    const handleValidateSignature = async () => {
      const res = await validateSignature(address, signMessageData);
      if (res?.data?.data?.accessToken) {
        localStorage.setItem("accessToken", res.data.data.accessToken);
        localStorage.setItem("walletAddress", address);

        dispatch(setWalletAddress(address));
      }
    };
    const token = localStorage.getItem("accessToken");
    if (signMessageData && !token) {
      handleValidateSignature();
    }
  }, [signMessageData]);
  useEffect(() => {
    if (error) {
      dispatch(setWalletAddress(null));
      disconnect();
    }
  }, [error]);
  const handleWalletConnect = async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      const res = await validateUser(address);
      const user = res.data.data;
      signMessage({
        message: `I am signing my one-time nonce: ${user?.nonce}`,
      });
    } else {
      dispatch(setWalletAddress(address));
    }
  };

  const handleClick = () => {
    // Toggle the darkMode state
    setDarkMode((prevMode) => !prevMode);
  }; 
   useEffect(() => {
 
    const loggedInAddress = localStorage.getItem("walletAddress");
    if (
      (loggedInAddress &&
        address &&
        loggedInAddress.toLocaleUpperCase() !== address.toLocaleUpperCase()) ||
      (loggedInAddress && !address)
    ) {
      logout();
    }
  }, [address]);
  const logout = () => {
    dispatch(setWalletAddress(null));
    localStorage.removeItem("accessToken");
    localStorage.removeItem("walletAddress");
    navigate("/login");
    disconnect();
  };
  return (
    <>
      <div
        className={`${Classes.header} d-flex align-items-center justify-content-between gap-2 w-100`}
      >
        <div className="d-flex align-items-center gap-2">
          <Button
            className={Classes.toggleBtn}
            icon={collapsed ? <BiChevronRight /> : <BiChevronLeft />}
            onClick={toggleCollapsed}
          />
          {/* <Input classNames={Classes.input} placeholder="Search..." /> */}
        </div>
        <div className="d-flex align-items-center gap-2 pe-3">
          {/* <div className={`${Classes.user} d-flex align-items-center gap-2`}>
            <img className={Classes.userImg} src={UserImg} alt="img" />
            <div className={Classes.userInfo}>
              <p className={Classes.name}>Ryan Crawford</p>
            </div>
          </div> */}
          <div
            className={`${Classes.walletOuter} d-flex align-items-center gap-md-4 gap-1 ps-md-4 ps-0`}
          >
            <button className={Classes.toggleMode} onClick={handleClick}>
              {darkMode ? (
                <Tooltip
                  placement="bottom"
                  title="Dark Mode"
                  trigger="click"
                  visible={tooltipVisible}
                  onVisibleChange={(visible) => setTooltipVisible(visible)}
                >
                  <Moon onClick={() => setTooltipVisible(true)} />
                </Tooltip>
              ) : (
                <Tooltip
                  placement="bottom"
                  title="Light Mode"
                  trigger="click"
                  visible={tooltipVisible}
                  onVisibleChange={(visible) => setTooltipVisible(visible)}
                >
                  <Sun
                    className={Classes.sunIcon}
                    onClick={() => setTooltipVisible(true)}
                  />
                </Tooltip>
              )}
            </button>
            <button
              onClick={() => {
                if (address) {
                  return;
                }
                open({ view: "Networks" });
              }}
              className={`${Classes.walletBtn} d-flex align-items-center gap-2`}
            >
              <img src={WalletImg} alt="icon" />
              <span>
                {address
                  ? address?.slice(0, 4) + "..." + address?.slice(-4)
                  : "Connect Wallet"}
              </span>
            </button>
            <button
              onClick={() => logout()}
              className={`${Classes.walletBtn} ${Classes.disconnect} d-flex align-items-center gap-2`}
            >
              Disconnect
            </button>
          </div>
        </div>
      </div>
      {/* <ConnectWallet isModalOpen={isModalOpen} handleCancel={handleCancel} /> */}
    </>
  );
};

export default HeaderComponent;
