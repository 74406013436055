import api from "../index";

  const getAllSettings = () => {
    return api.get('/settings')
  };

  const updateSettings = (id,data) => {
    return api.put(`/settings/${id}`,data)
  };
  
export { getAllSettings,updateSettings };
