// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Inter-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Inter-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Inter-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Inter-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@font-face {
    font-family: 'Inter';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: 300;
    font-style: light;
}
@font-face {
    font-family: 'Inter';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
    font-weight: 600;
    font-style: medium;
}
@font-face {
    font-family: 'Inter';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
    font-weight: 700;
    font-style: bold;
}
@font-face {
    font-family: 'Inter';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
    font-weight: 800;
    font-style: bolder;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/_fonts.css"],"names":[],"mappings":";AACA;IACI,oBAAoB;IACpB,+DAAuD;IACvD,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,oBAAoB;IACpB,+DAAyD;IACzD,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,oBAAoB;IACpB,+DAAwD;IACxD,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,oBAAoB;IACpB,+DAAsD;IACtD,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,oBAAoB;IACpB,+DAAsD;IACtD,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":["\n@font-face {\n    font-family: 'Inter';\n    src: url('../fonts/Inter-Light.ttf') format('truetype');\n    font-weight: 300;\n    font-style: light;\n}\n@font-face {\n    font-family: 'Inter';\n    src: url('../fonts/Inter-Regular.ttf') format('truetype');\n    font-weight: 400;\n    font-style: normal;\n}\n@font-face {\n    font-family: 'Inter';\n    src: url('../fonts/Inter-Medium.ttf') format('truetype');\n    font-weight: 600;\n    font-style: medium;\n}\n@font-face {\n    font-family: 'Inter';\n    src: url('../fonts/Inter-Bold.ttf') format('truetype');\n    font-weight: 700;\n    font-style: bold;\n}\n@font-face {\n    font-family: 'Inter';\n    src: url('../fonts/Inter-Bold.ttf') format('truetype');\n    font-weight: 800;\n    font-style: bolder;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
