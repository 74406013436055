import React from "react";
import { Modal } from "antd";
import Classes from "./confirmModal.module.scss";

function ConfirmModal({ isModalOpen, handleCancel,modalInfo,onConfirm,onReject,loader }) {
  return (
    <Modal
      className={Classes.disableModal}
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
    >
      <div className={Classes.content}>
        <p>{modalInfo?.message||''}</p>
        <div className="d-flex align-items-center justify-content-center gap-3">
          <button className={`${Classes.btn} ${Classes.reject}`} onClick={()=>onReject()}>No</button>
          <button className={`${Classes.btn} ${Classes.accept}`} onClick={()=>onConfirm()}>{loader?"loading":"Yes"}</button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmModal;
