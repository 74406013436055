import { useContractWrite, useWaitForTransaction } from "wagmi";
import { CONTRACT_INFO } from "../../index";

const platformFundContractAddress = CONTRACT_INFO.stake.address;
const abi = CONTRACT_INFO.stake.abi;

const useAddPlatformFund = () => {
  const {
    isError: isPlatformFundSentError,
    data: platformFundSentTx,
    isLoading: isPlatformFundTxInProgress,
    isSuccess: isPlatformFundTxSent,
    write: platformFund, // Function to interact with the platformFund contract
    error: platformFundSentError
  } = useContractWrite({
    address: platformFundContractAddress,
    abi,
    functionName: "addFunds", // Assuming this function remains the same in your ABI
  });

  const {
    isLoading: isPlatformFundWaiting,
    isSuccess: isPlatformFundCompleted,
    isError: isPlatformFundError,
    error: platformFundTxError,
    data: platformFundResp
  } = useWaitForTransaction({
    hash: platformFundSentTx?.hash,
  });

  return {
    // platformFund operation
    isPlatformFundSentError,
    platformFundSentTx,
    isPlatformFundTxInProgress,
    isPlatformFundTxSent,
    platformFund,
    isPlatformFundCompleted,
    isPlatformFundError,
    isPlatformFundWaiting,
    platformFundSentError,
    platformFundTxError,
    platformFundResp
  };
};

export default useAddPlatformFund;
