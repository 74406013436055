import React from "react";
import Classes from "./bot.module.scss";
import BotIcon from "../../assets/images/icons/yellowBot.svg";
import CopyIcon from "../../assets/images/icons/copy.svg";
import Graph from "../../assets/images/section/graph.svg";
import BNB from "../../assets/images/icons/bnb.svg";
import { Tooltip } from "antd";
import Profile from "../../assets/images/section/profile.svg";

function Bot() {
  return (
    <div className={Classes.bot}>
      <div className={Classes.heading}>
        <img src={BotIcon} alt="icon" />
        <p>DAO BOT</p>
      </div>
      <div className={Classes.card}>
        <div className="d-flex justify-content-md-between flex-md-row flex-column align-items-center mb-3">
          <div>
            <div
              className={`${Classes.address} d-flex align-items-center gap-4 mb-2`}
            >
              <span>0xf324...77fb</span>
              <Tooltip placement="top" title="Copy">
                <img src={CopyIcon} alt="icon" />
              </Tooltip>
            </div>
            <div
              className={`${Classes.amount} d-flex align-items-center gap-3`}
            >
              <h3>$3100.20</h3>
              <span>+2.90% ($285.85)</span>
            </div>
          </div>
          <div className="d-flex flex-column align-items-center">
            <img src={Graph} alt="graph" />
            <span className={Classes.data}>
              Date updated <b>16 sec</b> ago
            </span>
          </div>
        </div>
        <div className={`${Classes.bg} mb-4`}>
          <div className="d-flex align-items-center gap-3">
            <img src={BNB} alt="icon" />
            <div className="d-flex flex-column gap-1">
              <span className={Classes.label}>BNB Chain</span>
              <div className="d-flex align-items-center gap-4">
                <span className={Classes.txt}>3,100.20</span>
                <span className={Classes.txt}>100%</span>
              </div>
            </div>
          </div>
        </div>
        <div className={Classes.bg}>
          <div className="overflow-auto mb-3">
            <table className="w-100">
              <tbody>
                <tr>
                  <th>Asset</th>
                  <th>Price</th>
                  <th>Amount</th>
                  <th>USD Value</th>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center gap-1">
                      <img src={BNB} alt="icon" />
                      <span>
                        <b>WBNB</b>
                      </span>
                    </div>
                  </td>
                  <td>
                    <span>
                      <b>$596.60</b>
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>0.1591 WBNB</b>
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>$94.92</b>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={`${Classes.tabsOuter} d-flex align-items-center gap-2 my-4`}>
            <div className={Classes.infoTab}>0 Customized</div>
            <div className={Classes.infoTab}>0 Blocked</div>
          </div>
          <div className={`${Classes.swap} d-flex align-items-center justify-content-between my-4`}>
            <div className="d-flex align-items-center gap-2">
              <img src={Profile} alt="img" />
              <p className={Classes.userName}>PancakeSwap V3</p>
            </div>
            <b>$3,005.27</b>
          </div>
          <div className={Classes.poolContainer}>
            <div className={Classes.tile}>Liquidity Pool</div>
            <div className="px-2 py-5">
              <div className="overflow-auto mb-3">
                <table className="w-100">
                  <tbody>
                    <tr>
                      <th>ID</th>
                      <th>Pool</th>
                      <th>Balance</th>
                      <th>Rewards</th>
                      <th>USD Values</th>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <span>
                          <b>#1252805</b>
                        </span>
                      </td>
                      <td>
                        <div className="d-flex align-items-center gap-1">
                          <img src={BNB} alt="icon" />
                          <span>
                            <b>DOGE+BNB</b>
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="mb-1">
                          <b>11,695.67 DOGE</b>
                        </div>
                        <div>
                          <b>1.8705 BNB</b>
                        </div>
                      </td>
                      <td>
                        <div className="mb-1">
                          <b>86.5038 DOGE ($13.38)</b>
                        </div>
                        <div>
                          <b>0.22330 BNB ($13.30)</b>
                        </div>
                      </td>
                      <td>
                        <span>
                          <b>$3,005.27</b>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bot;
