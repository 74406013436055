import React, { useEffect, useState ,useContext} from "react";
import Classes from "./users.module.scss";
import User from "../../assets/images/icons/userYellowIcon.svg";
import UserImg from "../../assets/images/section/userImg.svg";
import {
  banUser,
  getAllUsers,
  unbanUser,
  updateUserStatus,
  updateUserInvestmentStatus,
} from "../../api/services/user";
import { toast } from "react-toastify";
import SocketContext from "../../context/socket";
import useDisableUser from "../../contract/hooks/stake/useDisableUser";
import { useAccount } from "wagmi";
function Users() {
  const [users, setUsers] = useState();
  const [selectedUser, setSelectedUser] = useState(null);
  const [loader,setLoader]=useState(null)
  const { address } = useAccount();
  const socket = useContext(SocketContext);
  const fetchUsers = async () => {
    const res = await getAllUsers();
    setUsers(res.data);
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  const { updateUserStatus, disableUserSentTx } = useDisableUser();

  useEffect(() => {
    if (disableUserSentTx?.hash) {
      updateUserStatus({ status: !selectedUser?.isBanned });
    }
  }, [disableUserSentTx?.hash]);

  const handleUser = async (user) => {
    try {
      setLoader(true)
      updateUserStatus({
        args: [user?.walletAddress,!user?.isBanned],
        from: address,
      });
      setSelectedUser(user);
    } catch (err) {
      console.log(err, "errror=.");
    }
  };
  const updateInvestmentStatus = async (id, status) => {
    try {
      const res = await updateUserInvestmentStatus(id, { status });
      if (res.status === 200) {
        fetchUsers();
        toast.success("User Investment status updated successfully");
      }
    } catch (err) {
      console.log(err, "errror=.");
    }
  };
  useEffect(() => {
    if (socket) {
      const handleUserStatus=(params)=>{ 
      toast.success(`User ${params?.status} successfully`)
      fetchUsers();
      setLoader(false)
      }
      socket?.on("UserAdminStatus", handleUserStatus);
      return () => {
        socket?.off("UserAdminStatus", handleUserStatus);
        // socket.emit("leave", `${user?.id}`);
      };
    }
  }, [socket]);
  return (
    <div className={Classes.user}>
      <div className={Classes.heading}>
        <img src={User} alt="icon" />
        <p>Users</p>
      </div>
      <div className={`${Classes.card}`}>
        {users &&
          users?.length > 0 &&
          users.map((user, i) => (
            <div
              className={`${Classes.list} d-flex align-items-center justify-content-between flex-sm-row flex-column`}
              key={i}
            >
              <div className="d-flex align-items-center gap-3">
                <img
                  className={Classes.img}
                  src={user?.profileImage || UserImg}
                  alt="img"
                />
                <div>
                  <div className="mb-2">
                    <p className={Classes.name}>{user?.name || "-no-name-"}</p>
                    <span className={Classes.userName}>
                      {user?.walletAddress.slice(0, 4) +
                        "..." +
                        user?.walletAddress.slice(-4)}
                    </span>
                    {user?.emergencyEmail && (
                      <div className={Classes.metaInfo}>
                        <p className={Classes.userName}>Emergency Email:</p>
                        <p>{user?.emergencyEmail || ""}</p>
                      </div>
                    )}
                    {user?.emergencyContact && (
                      <div className={Classes.metaInfo}>
                        <p className={Classes.userName}>Emergency Contact:</p>
                        <p>{user?.emergencyContact || ""}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex gap-3">
                <button
                  className={!user?.isBanned ? Classes.disable : Classes.enable}
                  onClick={() => handleUser(user)}
                  disabled={loader}
                >
                  {(loader&&selectedUser?._id===user?._id)?"loading":user?.isBanned ? "UnBan" : "Ban"}
                </button>

                {/* <button
                  className={
                    !user?.isReInvestmentEnabled
                      ? Classes.disable
                      : Classes.enable
                  }
                  onClick={() =>
                    updateInvestmentStatus(
                      user?._id,
                      !user?.isReInvestmentEnabled ? true : false
                    )
                  }
                >
                  {!user?.isReInvestmentEnabled
                    ? "Enable Re-Investment"
                    : "Disable Re-Investment"}
                </button> */}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Users;
