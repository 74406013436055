import { useContractWrite, useWaitForTransaction } from "wagmi";
import { CONTRACT_INFO } from "../../index";

const treasurePoolContractAddress = CONTRACT_INFO.stake.address;
const abi = CONTRACT_INFO.stake.abi;

const useAddTreasurePool = () => {
  const {
    isError: isTreasurePoolSentError,
    data: treasurePoolSentTx,
    isLoading: isTreasurePoolTxInProgress,
    isSuccess: isTreasurePoolTxSent,
    write: addTreasurePool, // Function to interact with the treasurePool contract
    error: treasurePoolSentError
  } = useContractWrite({
    address: treasurePoolContractAddress,
    abi,
    functionName: "addTreasuery", // Assuming this function remains the same in your ABI
  });


  const {
    isLoading: isTreasurePoolWaiting,
    isSuccess: isTreasurePoolCompleted,
    isError: isTreasurePoolError,
    error: treasurePoolTxError,
    data: treasurePoolResp
  } = useWaitForTransaction({
    hash: treasurePoolSentTx?.hash,
  });

  return {
    // treasurePool operation
    isTreasurePoolSentError,
    treasurePoolSentTx,
    isTreasurePoolTxInProgress,
    isTreasurePoolTxSent,
    addTreasurePool,
    isTreasurePoolCompleted,
    isTreasurePoolError,
    isTreasurePoolWaiting,
    treasurePoolSentError,
    treasurePoolTxError,
    treasurePoolResp
  };
};

export default useAddTreasurePool;
