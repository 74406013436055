// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardLayout_layout__-TpFw .dashboardLayout_sider__qcQ61 {
  position: fixed;
  left: 0;
  z-index: 1;
  height: 100vh;
}
.dashboardLayout_layout__-TpFw .dashboardLayout_headerOuter__p9q-x {
  position: fixed;
  z-index: 100;
}`, "",{"version":3,"sources":["webpack://./src/layout/DashboardLayout/dashboardLayout.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,OAAA;EACA,UAAA;EACA,aAAA;AAAJ;AAEE;EACE,eAAA;EACA,YAAA;AAAJ","sourcesContent":[".layout {\n  .sider {\n    position: fixed;\n    left: 0;\n    z-index: 1;\n    height: 100vh;\n  }\n  .headerOuter {\n    position: fixed;\n    z-index: 100;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `dashboardLayout_layout__-TpFw`,
	"sider": `dashboardLayout_sider__qcQ61`,
	"headerOuter": `dashboardLayout_headerOuter__p9q-x`
};
export default ___CSS_LOADER_EXPORT___;
