import { useContext, useEffect, useState } from "react";
import Classes from "./index.module.scss";
import { useAccount } from "wagmi";
import useAddPreInvestment from "../../contract/hooks/stake/useAddPreInvestment";
import { createPreInvestment } from "../../api/services/preInvestment";
import SocketContext from "../../context/socket";
import { useSelector } from "react-redux";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { validatePreInvestmentJsonFile } from "../../helpers/common";
const PreInvestment=()=>{
    const [file,setFile]=useState("")
    const [preInvestment,setPreInvestment]=useState({
        users:null,
        amount:null
    })
   
    const [loader,setLoader]=useState(false)
    const user = useSelector((state) => state.auth?.user);
    const { address } = useAccount();
    const socket = useContext(SocketContext);
    const {addPreInvestment,addPreInvestmentSentTx}=useAddPreInvestment({setLoader})

useEffect(()=>{
    if(addPreInvestmentSentTx?.hash){
        const formData=new FormData()
        formData.append('file',file)
        formData.append('txHash',addPreInvestmentSentTx.hash)

        createPreInvestment(formData)
    }
},[addPreInvestmentSentTx?.hash])

    const onChangeFile=(e)=>{
        const file = e.target.files[0];
        if (file && file.type === "application/json") {
            
          const reader = new FileReader();
          
          // This function will run once the file is read
          reader.onload = (e) => {
            try {
                const json = JSON.parse(e.target.result);
                const validationError=validatePreInvestmentJsonFile(json)
                if(validationError){
                 return toast.error(validationError)
                }
                setFile(file);
              

              const users=json.map((item)=>item.walletAddress)
              const amount=json.map((item)=>{
              const amount=  ethers.utils.parseEther(`${item.amount}`)
              return amount
              })
              setPreInvestment({users,amount})
             console.log({users,amount,json},"json==>")
            } catch (error) {
              console.error("Error parsing JSON:", error);
            }
          };
          
          // Read the file as text
          reader.readAsText(file);
        } else {
          alert("Please upload a valid JSON file.");
        }
    }

    const handlSubmit=()=>{
        setLoader(true)
       
        addPreInvestment({
            args: [preInvestment.amount,preInvestment.users],
            from: address,
        })
    }
    useEffect(() => {
        if (socket && user?._id) {
          socket.emit("join", `${user?._id}`);
          const handleAddPreInvestmentEvent=()=>{
            toast.success('Pr-Investments added successfully')
            setLoader(false)

          }
        
          socket?.on("PreInvestmentAdded", handleAddPreInvestmentEvent);
        
          return () => {
            socket?.off("PreInvestmentAdded", handleAddPreInvestmentEvent);
    
            socket.emit("leave", `${user?.id}`);
          };
        }
      }, [socket, user]);
    return (
        <>
          <div className={Classes.payout}>
            <div className={Classes.content}>
              <div className="d-flex align-items-center gap-2 mb-4 ps-3">
                {/* <img src={Leaf} alt="icon" /> */}
                <p className={Classes.title}>Pre-Investment</p>
              </div>
    
              <div className={`${Classes.card} mx-3 d-flex flex-column`}>
               <input className="mb-3" type="file" name="file" onChange={onChangeFile} accept=".json" />

            <button className="btn btn-primary" disabled={!file} onClick={()=>handlSubmit()}>{loader?"Loading":"Add"}</button>
              </div>
            </div>
          </div>

        </>
      );
}

export default PreInvestment