import api from "../index";
const validateUser = (address) => {
  return api.post(`/user/validate/wallet/${address}`);
};

const validateSignature=(address,signature)=>{
 return api.post(`/admin/verify-signature/${address}`,{signature})
}

export { validateUser,validateSignature };
